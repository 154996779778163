<template>
	<section>
		<v-stepper v-model="step" alt-labels>
			<v-stepper-header>
				<v-stepper-step :step="stepType"
				                :complete-icon="icons.yes"
				                :edit-icon="stepTypeComplete ? icons.yes : icons.edit"
				                editable :complete="stepTypeComplete"
				                class="stepper-step">
					{{ $t('user.terms.account') }}
				</v-stepper-step>
				<v-stepper-step :step="stepUser"
				                :complete-icon="icons.yes"
				                :edit-icon="stepUserComplete ? icons.yes : icons.edit"
				                :editable="stepTypeComplete" :complete="stepUserComplete"
				                class="stepper-step">
					{{ $t('user.singular') }}
				</v-stepper-step>
				<v-stepper-step :step="stepAddresses"
				                :complete-icon="icons.yes"
				                :edit-icon="stepAddressesComplete ? icons.yes : icons.edit"
				                :editable="stepTypeComplete && stepUserComplete" :complete="stepAddressesComplete"
				                class="stepper-step">
					{{ $t('address.plural') }}
				</v-stepper-step>
				<v-stepper-step :step="stepSummary"
				                :complete-icon="icons.yes"
				                :edit-icon="icons.yes"
				                :editable="stepTypeComplete && stepUserComplete && stepAddressesComplete && summaryStepVisited === true"
				                :complete="stepTypeComplete && stepUserComplete && stepAddressesComplete && summaryStepVisited === true"
				                class="stepper-step">
					{{ $t('ui.terms.summary') }}
				</v-stepper-step>
			</v-stepper-header>
			<v-stepper-items>
				<v-stepper-content :step="stepType" class="pa-0">
					<v-form ref="registrationForm" v-model="typeDataValid">
						<div class="ma-6">
							<AsiRadioGroup v-model="model.type" :rules="model.rules('type')">
								<AsiCard unwrapped :title="$t('constants.business')">
									<template v-slot:title>
										<div class="d-flex flex-row align-center pointer" @click="model.type = typeBusiness">
											<div class="mr-3">
												<v-radio :value="typeBusiness" class="ma-3"/>
											</div>
											<div class="headline">
												{{ $t('constants.business') }}
											</div>
										</div>
									</template>
									<v-card-text class="pa-6">
										<AsiTextField v-model="model.customerName"
										              :label="$t('customer.name')"
										              :placeholder="$t('customer.name')"
										              :rules="model.type === typeBusiness ? model.rules('customerName') : []"
										              :disabled="model.type !== typeBusiness"/>
										<AsiTextField v-model="model.phone"
										              :label="$t('customer.phone')"
										              :placeholder="$t('customer.phone')"
										              :rules="model.type === typeBusiness ? model.rules('phone') : []"
										              :disabled="model.type !== typeBusiness"
										              class="mt-4"/>
									</v-card-text>
								</AsiCard>
								<AsiCard unwrapped no-bottom-margin>
									<template v-slot:title>
										<div class="d-flex flex-row align-center pointer" @click="model.type = typePrivate">
											<div class="mr-3">
												<v-radio :value="typePrivate" class="ma-3"/>
											</div>
											<div class="headline">
												{{ $t('constants.private') }}
											</div>
										</div>
									</template>
								</AsiCard>
							</AsiRadioGroup>
						</div>
						<v-divider/>
						<div class="pa-6">
							<AsiCurrencyAutocomplete v-model="model.currency" :rules="model.rules('currency')"/>
						</div>
					</v-form>
					<v-divider/>
					<div class="px-6 py-3 grey lighten-5 d-flex flex-row justify-center align-center">
						<AsiStepperNavigation :next-disabled="!stepTypeComplete" color="secondary" small
						                      @next="step = stepUser" hide-prev/>
					</div>
				</v-stepper-content>

				<v-stepper-content :step="stepUser" class="pa-0">
					<v-form v-model="userDataValid">
						<div class="ma-6">
							<v-row>
								<v-col>
									<AsiTextField v-model="model.username"
									              :label="$t('ui.terms.username')"
									              :placeholder="$t('ui.terms.username')"
									              :rules="model.rules('username')"
									              autocomplete="username"/>
								</v-col>
							</v-row>
						</div>
						<v-divider/>
						<div class="ma-6">
							<v-row>
								<SalutationInput v-model="model.salutation"/>
							</v-row>
							<v-row>
								<v-col :cols="sMobile ? 12 : 9/2">
									<AsiTextField v-model="model.firstname" :rules="model.rules('firstname')"
									              :label="$t('user.firstname')" :placeholder="$t('user.firstname')"/>
								</v-col>
								<v-col :cols="sMobile ? 12 : 9/2">
									<AsiTextField v-model="model.lastname" :rules="model.rules('lastname')"
									              :label="$t('user.lastname')" :placeholder="$t('user.lastname')"/>
								</v-col>
							</v-row>
						</div>
						<v-divider/>
						<div class="ma-6">
							<v-row>
								<v-col>
									<AsiTextField v-model="model.customerPhone" :rules="model.rules('customerPhone')"
									              :label="$t('user.phone')" :placeholder="$t('user.phone')"/>
								</v-col>
							</v-row>
						</div>
						<v-divider/>
						<div class="ma-6">
							<v-row>
								<v-col>
									<AsiLocaleAutocompleteInput v-model="model.locale" :rules="model.rules('locale')"
									                            :label="$t('user.locale')" :placeholder="$t('user.locale')"/>
								</v-col>
							</v-row>
						</div>
					</v-form>
					<v-divider/>
					<div class="px-6 py-3 grey lighten-5 d-flex flex-row justify-center align-center">
						<AsiStepperNavigation :next-disabled="!stepUserComplete" color="secondary" small
						                      @prev="step = stepType" @next="step = stepAddresses"/>
					</div>
				</v-stepper-content>

				<v-stepper-content :step="stepAddresses" class="pa-0">
					<div v-if="model.addresses.length > 0" class="ma-6">
						<AddressForm v-for="(address, i) in model.addresses" :key="i" v-model="addressesValid[i]"
						             :model="address" :allow-delete="i !== 0" @requestDelete="deleteAddress(i)"
						             :allow-type-selection="model.type !== typeBusiness" :address-type="model.type === typeBusiness ? AddressType.addressBusiness : AddressType.addressPrivate"
						             @requestDefaultShippingAddress="setDefaultShippingAddress(i, $event)"
						             @requestDefaultBillingAddress="setDefaultBillingAddress(i, $event)"/>
					</div>
					<p v-else class="ma-6 text-center grey--text">
						{{ $t('address.terms.noAddresses') }}
					</p>
					<v-divider/>
					<div class="d-flex flex-row justify-center align-center pa-6">
						<AsiBtn :icon="icons.add" @click="addAddress" color="primary">
							{{ $t('address.terms.add') }}
						</AsiBtn>
					</div>
					<v-divider/>
					<div class="px-6 py-3 grey lighten-5 d-flex flex-row justify-center align-center">
						<AsiStepperNavigation :next-disabled="!stepAddressesComplete" color="secondary" small
						                      @prev="step = stepUser" @next="step = stepSummary"/>
					</div>
				</v-stepper-content>

				<v-stepper-content :step="stepSummary" class="pa-0">
					<div class="pa-6">
						<RegistrationSummary :model="model"/>
					</div>

					<v-expand-transition>
						<div v-show="createState !== createStateNone">
							<v-divider/>
							<div class="pa-6">
								<AsiAlert v-if="createState === createStateFail" type="error" :icon="icons.error" no-bottom-margin>
									{{ $t('user.terms.registrationError') }}
								</AsiAlert>
								<AsiAlert v-if="createState === createStateSuccess" type="success" :icon="icons.yes" no-bottom-margin>
									{{ $t('user.terms.registrationSuccess') }}
								</AsiAlert>
							</div>
						</div>
					</v-expand-transition>

					<v-divider/>
					<div class="px-6 py-3 grey lighten-5 d-flex flex-row justify-center align-center">
						<AsiStepperNavigation @prev="step = stepAddresses" hide-next color="secondary" small>
							<template v-slot:append>
								<AsiBtn :icon="icons.yes" :disabled="loading || createState === createStateSuccess" :loading="loading" @click="performRegistration"
								        prevent-icon-mode color="success">
									{{ $t('ui.create') }}
								</AsiBtn>
							</template>
						</AsiStepperNavigation>
					</div>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</section>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import Registration from "@/models/registration/Registration";
	import AsiTextField from "@/components/common/AsiTextField";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AddressForm from "@/components/address/AddressForm.vue";
	import AddressCreate from "@/models/address/AddressCreate";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import {AddressType, CustomerType} from "@/helpers/constants";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiStepperNavigation from "@/components/common/AsiStepperNavigation.vue";
	import AsiAlert from "@/components/common/AsiAlert.vue";
	import AsiCurrencyAutocomplete from "@/components/common/AsiCurrencyAutocomplete.vue";
	import RegistrationSummary from "@/components/user/shop/RegistrationSummary.vue";
	import AsiLocaleAutocompleteInput from "@/components/common/AsiLocaleAutocompleteInput.vue";
	import SalutationInput from "@/components/common/SalutationInput.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

	@Component({
		computed: {
			AddressType() {
				return AddressType;
			}
		},
		components: {
			SalutationInput,
			RegistrationSummary,
			AsiCurrencyAutocomplete,
			AsiLocaleAutocompleteInput,
			AsiAlert, AsiStepperNavigation, AsiCard, AsiRadioGroup, AddressForm, AsiBtn, AsiTextField
		}
	})
	export default class RegistrationWizard extends mixins(ResponsiveChecks) {

		@Prop({type: Boolean, default: false})
		public simple!: boolean;

		private readonly stepType = 1;
		private readonly stepUser = 2;
		private readonly stepAddresses = 3;
		private readonly stepSummary = 4;
		private readonly typeBusiness = CustomerType.business;
		private readonly typePrivate = CustomerType.private;
		private readonly createStateNone = 0;
		private readonly createStateStarted = 1;
		private readonly createStateSuccess = 2;
		private readonly createStateFail = 3;

		private icons = Icon;
		private loading: boolean = false;
		private showPassword: boolean = false;
		private step: number = this.stepType;
		private model: Registration = new Registration();
		private typeDataValid: boolean = false;
		private userDataValid: boolean = false;
		private addressesValid: boolean[] = [];
		private createState: number = this.createStateNone;
		private createdId: string | null = null;
		private addressStepVisited: boolean = false;
		private summaryStepVisited: boolean = false;

		private get stepTypeComplete(): boolean {
			return this.typeDataValid;
		}

		private get stepUserComplete(): boolean {
			return this.userDataValid;
		}

		private get stepAddressesComplete(): boolean {
			return this.addressDataValid && this.addressStepVisited;
		}

		private get addressDataValid(): boolean {
			return !this.addressesValid.some(v => !v);
		}

		private get textFieldComponent(): string {
			return this.simple ? AsiTextFieldSimple.name : AsiTextField.name;
		}

		private addAddress(): void {
			this.createAddress();
		}

		private createAddress(): void {
			const address = new AddressCreate();
			if (this.model.addresses.length === 0) {
				address.isDefaultShippingAddress = true;
				address.isDefaultBillingAddress = true;
			}
			this.model.addresses.push(address);
			this.addressesValid.push(true);
		}

		public mounted(): void {
			this.createAddress();
		}

		@Watch('step')
		private onStepChanged(value: number): void {
			if (value === this.stepUser && this.model.type === this.typeBusiness && this.model.customerPhone === null) {
				this.model.customerPhone = this.model.phone;
			} else if (value === this.stepAddresses) {
				this.model.addresses[0].company = this.model.customerName;
				this.model.addresses[0].salutation = this.model.salutation;
				this.model.addresses[0].firstname = this.model.firstname;
				this.model.addresses[0].lastname = this.model.lastname;
				this.addressStepVisited = true;
			} else if (value === this.stepSummary) {
				this.summaryStepVisited = true;
			}
		}

		@Watch('model.type')
		private onModelTypeChanged(value: CustomerType): void {
			if (value === CustomerType.private) {
				this.model.customerName = null;
				this.model.phone = null;
				const form = this.$refs.registrationForm as HTMLFormElement;
				form.resetValidation();
			}
		}

		private performRegistration(): void {
			if (this.model.type === this.typePrivate) {
				this.model.phone = this.model.customerPhone;
			}

			this.createState = this.createStateStarted;
			this.loading = true;
			this.$userServiceShop.registration(this.model)
				.then(res => {
					this.createState = this.createStateSuccess;
					this.createdId = res.id;
				})
				.catch(() => this.createState = this.createStateFail)
				.finally(() => this.loading = false);
		}

		private deleteAddress(index: number): void {
			if (index !== 0) {
				this.model.addresses = this.model.addresses.filter((a: AddressCreate, i: number) => i !== index);
				this.addressesValid = this.addressesValid.filter((a: boolean, i: number) => i !== index);
			}
		}

		private setDefaultShippingAddress(index: number, value: boolean): void {
			this.model.addresses.forEach((a: AddressCreate, i: number) => a.isDefaultShippingAddress = value && i === index);
		}

		private setDefaultBillingAddress(index: number, value: boolean): void {
			this.model.addresses.forEach((a: AddressCreate, i: number) => a.isDefaultBillingAddress = value && i === index);
		}

	}
</script>

<style lang="scss" scoped>
	.pointer {
		cursor: pointer;
	}

	.stepper-step {
		flex-basis: auto !important;
	}
</style>
