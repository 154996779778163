<template>
	<AsiCard>
		<v-card-text>
			<v-form v-model="validInternal">
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiRadioGroup v-model="business" row class="ma-0">
								<v-radio :value="true" :label="$t('constants.addressBusiness')" class="mr-6"/>
								<v-radio :value="false" :label="$t('constants.addressPrivate')" class="mr-6"/>
							</AsiRadioGroup>
						</v-col>
					</v-row>
					<v-row v-if="business">
						<v-col :cols="12">
							<AsiTextField v-model="model.company" :rules="business ? model.rules('company') : []"
							              :label="$t('contactForm.company')" :placeholder="$t('contactForm.company')"
							              class="input-required"
							/>
						</v-col>
					</v-row>
				</div>
				<v-divider/>
				<div class="pa-6">
					<v-row>
						<SalutationInput v-model="model.salutation"/>
					</v-row>
					<v-row>
						<v-col :cols="sMobile ? 12 : 9/2">
							<AsiTextField v-model="model.firstname" :rules="model.rules('firstname')"
							              :label="$t('contactForm.firstname')" :placeholder="$t('contactForm.firstname')"
							              autocomplete="given-name"
							              class="input-required"/>
						</v-col>
						<v-col :cols="sMobile ? 12 : 9/2">
							<AsiTextField v-model="model.lastname" :rules="model.rules('lastname')"
							              :label="$t('contactForm.lastname')" :placeholder="$t('contactForm.lastname')"
							              autocomplete="family-name"
							              class="input-required"/>
						</v-col>
					</v-row>
				</div>
				<v-divider/>
				<div class="pa-6">
					<v-row>
						<v-col :cols="sMobile ? 12 : 10">
							<AsiTextField v-model="model.street" :rules="model.rules('street')"
							              :label="$t('contactForm.street')" :placeholder="$t('contactForm.street')"
							              class="input-required"/>
						</v-col>
						<v-col :cols="sMobile ? 12 : 2">
							<AsiTextField v-model="model.houseNumber" :rules="model.rules('houseNumber')"
							              :label="$t('contactForm.houseNumber')" :placeholder="$t('contactForm.houseNumber')"
							              class="input-required"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col :cols="sMobile ? 12 : 6">
							<AsiTextField v-model="model.zip" :rules="model.rules('zip')"
							              :label="$t('contactForm.zip')" :placeholder="$t('contactForm.zip')"
							              autocomplete="postal-code"
							              class="input-required"/>
						</v-col>
						<v-col :cols="sMobile ? 12 : 6">
							<AsiTextField v-model="model.city" :rules="model.rules('city')"
							              :label="$t('contactForm.city')" :placeholder="$t('contactForm.city')"
							              autocomplete="address-level2"
							              class="input-required"/>
						</v-col>
					</v-row>
				</div>
				<v-divider/>
				<div class="pa-6">
					<v-row>
						<v-col :cols="sMobile ? 12 : 6">
							<AsiTextField v-model="model.phone" :rules="model.rules('phone')"
							              :label="$t('contactForm.phone')" :placeholder="$t('contactForm.phone')"
							              class="input-required"/>
						</v-col>
						<v-col :cols="sMobile ? 12 : 6">
							<AsiTextField v-model="model.email" :rules="model.rules('email')"
							              :label="$t('contactForm.email')" :placeholder="$t('contactForm.email')"
							              class="input-required"/>
						</v-col>
					</v-row>
				</div>
				<v-divider/>
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiTextarea v-model="model.message" :rules="model.rules('message')"
							             :label="$t('contactForm.message')" :placeholder="$t('contactForm.message')"
							             class="input-required"/>
						</v-col>
					</v-row>
				</div>
				<div class="d-flex flex-column align-center px-6">
					<AsiCheckbox v-model="isPrivacyConditionsAccepted">
						<template v-slot:label>
							<i18n path="contactForm.confirmPrivacy">
								<template v-slot:link>
									<span class="primary--text" style="cursor: pointer" @click="dpDialog.open()">
										{{ $t('pageTitles.shop.privacy') }}
									</span>
								</template>
							</i18n>
						</template>
					</AsiCheckbox>
				</div>
				<div class="pa-6 d-flex justify-center">
					<AsiBtn large :block="sMobile" color="primary" :icon="icons.mail" @click="sendContactForm()"
					        :disabled="!(valid && isPrivacyConditionsAccepted)">
						{{ $t('contactForm.send') }}
					</AsiBtn>
				</div>
			</v-form>
		</v-card-text>
		<AsiDialog v-if="dpDialog.isLoaded" :open="dpDialog.isOpen"
		           :title="$t('pageTitles.shop.privacy')"
		           :subtitle="tenantName"
		           :icon="icons.toc"
		           @cancel="dpDialog.close()" scrollable>
			<PrivacyContent/>
		</AsiDialog>
	</AsiCard>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import ShopView from "@/components/layout/ShopView.vue";
	import Icon from "@/plugins/icons";
	import SalutationInput from "@/components/common/SalutationInput.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import {mixins} from "vue-class-component";
	import AsiCountryAutocompleteInput from "@/components/common/AsiCountryAutocompleteInput.vue";
	import AsiTextarea from "@/components/common/AsiTextarea";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
	import AsiDialog from "@/components/common/AsiDialog.vue";
	import EnumHelper from "@/helpers/EnumHelper";
	import {Tenant} from "@/models/ui/Tenant";
	import PrivacyContent from "@/views/shop/legal/PrivacyContent.vue";
	import ContactFormCreate from "@/models/contact/ContactFormCreate";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";

	@Component({
		components: {
			AsiRadioGroup,
			PrivacyContent,
			Privacy: PrivacyContent,
			AsiDialog, LegalTermsAndConditions,
			AsiTextFieldSimple,
			AsiCard,
			AsiCardDetail,
			AsiCheckbox,
			AsiBtn, AsiTextarea, AsiCountryAutocompleteInput, AsiTextField, SalutationInput, ShopView
		}
	})
	export default class ContactForm extends mixins(ResponsiveChecks) {
		@Prop({type: Object, required: true})
		public model!: ContactFormCreate;

		@Prop({type: Boolean, required: true})
		public valid!: boolean;

		private icons = Icon;
		private validInternal: boolean = true;
		private isPrivacyConditionsAccepted = false;
		private dpDialog: DialogHandler = new DialogHandler();
		private business: boolean = false;

		private get tenantName(): string {
			return EnumHelper.textFromValue(Tenant, this.$isTenant, true) ?? '';
		}

		private sendContactForm(): void {
			if (this.valid && this.isPrivacyConditionsAccepted) {
				this.performContactForm();
			}
		}

		@Watch('business')
		private onBusinessChange(): void {
			this.model.company = "";
		}

		@Watch('validInternal')
		public onValidInternalChange(): void {
			this.change(this.validInternal);
		}

		@Emit('change')
		public change(valid: boolean): boolean {
			return valid;
		}

		@Emit('performContactForm')
		public performContactForm(): boolean {
			return true;
		}
	}
</script>

<style scoped lang="scss">

</style>
