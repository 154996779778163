<template>
	<ShopView :path="path" :show-aside="rootCategories.length < 2 ? rootCategories.length > 0 : childCategories.length > 0" :full-width="!sDesktop">
		<template v-slot:prepend>
			<CategoryHeader :category="detailModel" :loading="itemsLoading"/>
		</template>

		<template v-slot:aside>
			<CategoryTree v-if="detailModel !== null" :title="$t(rootCategories.length < 2 ? 'category.plural' : 'category.subCategory.plural')"
			              :top-level-categories="rootCategories.length < 2 ? null : childCategories"
			              :active-category-id="detailModel.id"/>
		</template>

		<template v-if="showItems && (!itemsLoadedDone || numItems > 0)">
			<h2 class="mb-6 mt-6">{{ $t('item.plural') }}</h2>
			<ItemList :category-id="this.category.id" :table-view="isTableView" memory-key-filter="item-list" :memory-key-id="this.category.id" :loading.sync="itemsLoading"
			          :show-attribute-callback="showColumn" @itemsLoaded="itemsLoaded"/>
		</template>
		<template v-else>
			<div class="w-100 d-flex justify-center text--disabled text-center">
				<h2 class="title my-10">{{ $t('category.noItemsInCategory') }}</h2>
			</div>
		</template>
	</ShopView>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import PathEntry from "@/models/PathEntry";
	import CategoryNavigation from "@/components/category/shop/CategoryNavigation.vue";
	import {ICategoryShop, ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
	import CategoryHelper from "@/models/category/CategoryHelper";
	import ItemList from "@/components/item/shop/ItemList.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import PathHelper from "@/helpers/PathHelper";
	import Snackbar from "@/helpers/Snackbar";
	import {CategoryView, ItemAttributeVisibility} from "@/helpers/constants";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import ShopView from "@/components/layout/ShopView.vue";
	import CategoryTree from "@/components/category/shop/CategoryTree.vue";
	import CategoryHeader from "@/components/category/shop/CategoryHeader.vue";
	import {AxiosError} from "axios";
	import router from "@/router";
	import RoutesHelper from "@/helpers/RoutesHelper";

	@Component({
		components: {
			CategoryHeader,
			CategoryTree,
			ShopView,
			AsiBtn,
			ItemList,
			CategoryNavigation,
		}
	})
	export default class Category extends mixins(ResponsiveChecks) {

		@Prop({type: String, required: true})
		public canonical!: string;

		private loading: boolean = false;
		private itemsLoading: boolean = false;
		private itemsLoadedDone: boolean = false;
		private numItems: number = 0;
		private detailModel: ICategoryShop | null = null;

		public created(): void {
			this.loadDetailModel();
		}

		private get path(): PathEntry[] {
			const path = this.category === null ? [] : CategoryHelper.path(this.category);
			if (this.category !== null) path.push(CategoryHelper.pathEntry(this.category));
			PathHelper.prependHomeShop(path);
			return path;
		}

		private get isTableView(): boolean {
			return this.category?.view === CategoryView.tableView ?? false;
		}

		private get category(): ICategoryShopListEntry | null {
			return this.$store.getters['category/categoryByAnyCanonical'](this.canonical);
		}

		private get childCategories(): ICategoryShopListEntry[] {
			if (this.category === null) return [];
			const rootId = this.category.path.length === 0 ? this.category.id : this.category.path[0].id;
			return this.$store.getters['category/categoryChildren'](rootId);
		}

		private get showItems(): boolean {
			if (this.detailModel === null) return true;
			return this.detailModel.showItems;
		}

		private get rootCategories(): ICategoryShopListEntry[] {
			return this.$store.getters['category/rootCategories'];
		}

		@Watch('category')
		private onCategoryChanged(): void {
			this.loadDetailModel();
			this.itemsLoadedDone = false;
			this.numItems = 0;
		}

		@Watch('showItems')
		onShowItemsChange(newValue: boolean): void {
			if (!newValue && !this.itemsLoadedDone) {
				this.itemsLoadedDone = true;
				this.itemsLoading = false;
			}
		}

		private showColumn(column: string): boolean {
			if (this.category === null) return false;
			// @ts-ignore
			const visibilityValue: ItemAttributeVisibility = this.category[`${column}Visibility`] ?? ItemAttributeVisibility.never;

			switch (visibilityValue) {
				case ItemAttributeVisibility.largeView:
					return this.sDesktop;
				case ItemAttributeVisibility.mediumView:
					return this.sDesktop || this.sSemiMobile;
				case ItemAttributeVisibility.smallView:
					return true;
				case ItemAttributeVisibility.never:
				case ItemAttributeVisibility.detailViewOnly:
				default:
					return false;
			}
		}

		private itemsLoaded(items: IItemShopListEntry[]): void {
			this.itemsLoadedDone = true;
			this.numItems = items.length;
		}

		private loadDetailModel(): void {
			if (null != this.category?.id) {
				this.loading = true;
				this.$categoryServiceShop.category(this.category.id)
					.then(model => {
						this.detailModel = model;
						this.$store.commit('category/setActiveRootCategory', model.path.length === 0 ? model.id : model.path[0].id);
					})
					.catch((err: AxiosError) => {
						if (err?.response?.status === 404) {
							router.push(RoutesHelper.notFoundRoute()).then(() => {
								router.go(0); /* refresh is needed for 404 status code (nginx.conf) */
							});
						} else {
							Snackbar.loadingError();
						}
					})
					.finally(() => this.loading = false);
			} else {
				Snackbar.loadingError();
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
